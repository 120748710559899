@import "../../../theme/variables.scss";
.wrapperBanner {
  width: 100%;
  height: 100%;
  padding-inline: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  align-items: center;
  padding-top: 197px;
  .relativeButton {
    display: flex;
    justify-content: center;
    position: relative;
    .wrapperCarousel {
      display: flex;
      align-items: center;
      overflow: hidden;
      max-width: 1440px;
      width: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        box-shadow: 0 3px 5px rgba(43, 43, 43, 0.371);
        z-index: 1;
        flex-shrink: 0;
      }
    }
    .active {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 0 3px 5px rgba(43, 43, 43, 0.371);
      transition: 0.5s;
      display: inherit;
    }
    .leftIcon,
    .rightIcon {
      position: absolute;
      width: 4%;
      aspect-ratio: 10/10;
      border-radius: 50%;
      background-color: $NEUTRAL-10;
      top: 38%;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 3px 5px rgba(43, 43, 43, 0.371);
      &:hover {
        border: 1px solid $NEW-PRIMARY2-BASE;
      }
    }
    .leftIcon {
      left: -2%;
      .iconL {
        width: 26px;
        height: 26px;
        transform: rotate(180deg);
      }
    }
    .rightIcon {
      right: -2%;
      .iconR {
        width: 26px;
        height: 26px;
      }
    }
  }
  .currentActve {
    width: 40px;
    height: 8px;
    display: flex;
    gap: 8px;
    .isActive {
      width: 8px;
      height: 8px;
      background-color: $NEUTRAL-40;
      border-radius: 50%;
    }
    .isActived {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $NEW-PRIMARY2-BASE;
    }
  }
}
@media (max-width: 768px) {
  .wrapperBanner {
    padding-top: 60px;
    .relativeButton {
      .leftIcon,
      .rightIcon {
        width: calc(6% + 3px);
        top: 42%;
      }
      .leftIcon {
        left: -3%;
        .iconL {
          width: calc(18px + 2px);
          height: calc(18px + 2px);
        }
      }
      .rightIcon {
        right: -3%;
        .iconR {
          width: calc(18px + 2px);
          height: calc(18px + 2px);
        }
      }
      .wrapperCarousel {
        overflow: auto;
        scroll-snap-type: x mandatory;
        &::-webkit-scrollbar {
          display: none;
        }
        > img {
          scroll-snap-align: start;
        }
      }
    }
  }
}
