@use "../../../theme/variables.scss" as *;
#categories {
  scroll-margin: 70px;
}
.container {
  margin: 0;
  padding: 100px 32px;
  padding-bottom: 180px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  justify-self: center;
  width: 100%;
  .headerClassCategory {
    scroll-margin: 150px;

    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding-block: 40px 20px;
    text-align: center;
    h1 {
      color: $NEUTRAL-100;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      position: relative;
      width: fit-content;
      > img[alt="stars"] {
        position: absolute;
        left: -28.5px;
        top: -16.999px;
        height: 40px;
        width: 40px;
      }
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
  }
  .mainContainCategory {
    padding: 24px;
    @include flexCol(20px);
    border-radius: 12px;
    background: $NEUTRAL-10;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
      0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    width: 100%;
    justify-content: center;
    .title {
      color: $NEUTRAL-100;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    .wrapCategory {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
      gap: 12px;
      > div {
        padding: 10px;
        border-radius: 8px;
        border: 1px solid $NEUTRAL-30;
        transition: 0.3s;
        &:hover {
          border-color: $NEW-PRIMARY2-BASE;
        }
        background-color: $NEUTRAL-10;
        @include flexRow(10px);
        align-items: center;
        cursor: pointer;
        > img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        > h3 {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
    }
  }
}
@media (max-width: 768px) {
  .container {
    .mainContainCategory {
      width: calc(100% - 2rem);
      padding: 16px;
      .wrapCategory {
        grid-template-columns: repeat(auto-fit, minmax(140px, 2fr));
        align-items: center;
        > div {
          gap: 2px !important;
          padding: 8px;
          > h3 {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    padding: 0;
    .headerClassCategory {
      padding: 24px;
      width: 100% !important;
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        > img[alt="stars"] {
          position: absolute;
          left: -18.5px;
          top: 0;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
