.container {
  display: flex;
  width: 500px;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  .header {
    display: flex;
    gap: 8px;
    align-items: center;
    > p {
      color: var(--Neutral-90, #404040);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
  }
  .contentWrapper {
    > p {
      color: var(--light-paragraph, #333);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      white-space: pre-line;
    }
  }
  .actionWrapper {
    display: flex;
    width: 100%;
    gap: 8px;
    > * {
      flex: 1;
    }
  }
}
