@import "../../../../../theme/variables.scss";

.container {
  padding: 16px 24px;
  .breadcrumbsWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    .backIcon {
      box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1),
        0px 6px 14px -6px rgba(24, 39, 75, 0.12);
      background: #fff;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .breadcrumb {
      display: flex;
      gap: 4px;
      align-items: center;
      > p {
        color: $NEUTRAL-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      & > :last-child {
        color: $NEUTRAL-100;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    > .buttonWrapper {
      display: flex;
      gap: 12px;
    }
  }
  .tag {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    > p {
      color: var(--Neutral-90, #404040);
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      text-transform: capitalize;
    }
    &[status="PUBLISH"] {
      background: var(--Success-Success-20, #ddf9a4);
    }
    &[status="DRAFT"] {
      background: var(--New-Primary-3-20, #dbf1fb);
    }
    &[status="UNPUBLISH"] {
      background: var(--Danger-Danger-20, #fac8a3);
    }
  }
  .contentWrapper {
    .imageWrapper {
      width: 100%;
      height: 330px;
      border-radius: 8px;
      position: relative;
      margin-bottom: 23px;
      border: 1px solid $NEUTRAL-40;
      .category {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px 0px 4px 4px;
        background: var(--New-Primary-1-Primary-50, #3c3b91);
        backdrop-filter: blur(5px);
        display: flex;
        padding: 6px 12px;
        gap: 4px;

        > p {
          color: var(--Neutral-10, #fff);
          font-size: 12px;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
      }
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      border-radius: 8px;

      }
    }
    .content {
      > :first-child {
        color: $NEUTRAL-90;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px; /* 125% */
        margin-bottom: 8px;
      }
      .dateWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 24px;
        > p {
          color: $NEUTRAL-70;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
