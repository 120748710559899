@import "../../../theme/variables.scss";

.container {
  position: relative;

  .startIcon {
    position: absolute;
    top: 25%;
    left: 12px;
  }

  .endIcon {
    position: absolute;
    top: 25%;
    right: 12px;
  }

  &.pinpoint {
    display: flex;
    align-items: center;

    > input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .pinpointButton {
      // position: absolute;
      // right: 0;
      // top: 0;
      height: 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 6px;
      /* Text-L/Medium */
      font-family: $BASE-FONT-FAMILY;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #9360a8;
      padding: 10px 16px;
      border: 1px solid $NEUTRAL-40;
      border-radius: 0 8px 8px 0;
      border-left: none;
    }
  }

  .deleteButton {
    position: absolute;
    right: -24px;
    top: 30%;
    display: grid;
    place-items: center;
  }

  .phoneCurrencyAdornment {
    position: absolute;
    left: 1px;
    top: 1px;
    height: calc(100% - 2px);
    background-color: $NEUTRAL-30;
    border-radius: 8px 0 0 8px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL-60;
    width: 45px;
    display: grid;
    place-items: center;
    border-right: 1px solid $NEUTRAL-40;
  }
  .percentageAdornment {
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    background-color: $NEUTRAL-30;
    border-radius: 0 8px 8px 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL-60;
    width: 45px;
    display: grid;
    place-items: center;
    border-right: 1px solid $NEUTRAL-40;
  }

  > input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > textarea {
    resize: vertical;
    height: 82px;
  }

  > input,
  > textarea {
    font-family: $BASE-FONT-FAMILY;
    background-color: $NEUTRAL-10;
    width: 100%;
    outline: none;
    border: 1px solid $NEUTRAL-40;
    transition: all 0.3s;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      border-color: $NEW-PRIMARY2-40;
    }

    &:focus {
      border-color: $NEW-PRIMARY2-BASE;
    }

    &::placeholder {
      font-family: $BASE-FONT-FAMILY;
      // font-size: 13px;
      // font-style: normal;
      // font-weight: 400;
      // line-height: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: $NEUTRAL-50;
      line-height: 20px;
    }

    &:disabled {
      background-color: $NEUTRAL-30;
      border-color: $NEUTRAL-50;
    }
  }

  .spButton {
    position: absolute;
    top: 12px;
    right: 12px;

    &:hover {
      color: $NEW-PRIMARY2-BASE;
    }
  }

  .dropdownAdornment {
    position: absolute;
    top: 0;
    right: 12px;
    height: 50px;
  }

  .spinner {
    position: absolute;
    right: 38px;
    top: 8px;
  }

  .optionsWrapper {
    position: absolute;
    width: 100%;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
      0px 3px 5px rgba(9, 30, 66, 0.2);
    z-index: 1;
    padding-block: 4px;
    max-height: 200px;
    overflow: auto;

    > div {
      padding: 8px 12px;
      font-family: $BASE-FONT-FAMILY;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_90;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: $NEUTRAL-20;
      }

      &.active {
        background-color: $NEW-PRIMARY2-10;
      }

      &.loadingText {
        color: $NEUTRAL-70;
      }

      // >img {
      //   width: 20px;
      //   aspect-ratio: 1;
      //   border-radius: 50%;
      //   object-fit: cover;
      // }
    }

    .actionsWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: transparent;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;

        span {
          font-family: "Poppins";
          font-size: 13px;
        }

        &:disabled {
          opacity: 0;
          // display: none;
        }
      }
    }
  }
}

.fileInput {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 12px;

  &.errorInput {
    border: 2px solid #b22a12;
    background: #fff;
  }

  &.disabled {
    background-color: $NEUTRAL-20;
  }

  > *:first-child {
    flex-shrink: 0;
  }

  .fileContent {
    flex-grow: 1;
    width: 80%;
    display: flex;
    flex-direction: column;

    > span {
      font-family: $BASE-FONT-FAMILY;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: $NEUTRAL-50;
    }

    .fileDesc {
      display: flex;
      flex-direction: column;
      gap: 6px;

      > p {
        font-family: $BASE-FONT-FAMILY;
        // font-size: 16px;
        @include font-size(16, 14, 14px);
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        color: $NEUTRAL-90;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      > span {
        font-family: $BASE-FONT-FAMILY;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $NEUTRAL-70;
      }
    }
  }

  .chooseButton {
    padding: 8px 16px;
    font-size: 16px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.errorWrapper {
  margin-top: 4px;
  > p {
    color: var(--Secondary-3-600, #b22a12);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
}
