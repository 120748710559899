@use "../../../theme/variables.scss" as *;
.articleWrapper {
  width: 100vw;
  background-color: $NEUTRAL-10;
  scroll-margin: 100px;
  padding-bottom: 40px;
  //   box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.179);
}
.articleLanding {
  @include flexCol(20px);
  padding: 32px;
  max-width: 1440px;
  justify-self: center;
  .header {
    @include flexBetween();
    align-items: center;
    > h2 {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
  }
  .mainContain {
    @include flexRow(20px);
    .left {
      .articleNewest {
        height: 100%;
        width: 100%;
        display: flex;
        position: relative;
        cursor: pointer;
        .shadowInsetImg {
          position: absolute;
          height: 200px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          bottom: 0;
          box-shadow: 0 -16px 10px rgba(0, 0, 0, 0.1);
          filter: blur(30px);
        }
        > img {
          width: 598px;
          height: 495px;
          flex-shrink: 0;
          object-fit: cover;
          border-radius: 8px;
        }
        .descriptionContent {
          position: absolute;
          padding: 16px;
          bottom: 0;
          color: $NEUTRAL-10;
          @include flexCol(12px);
          width: 100%;
          .category {
            @include flexRow(8px);
            .name {
              @include flexRow(8px);
              align-items: center;
              padding: 6px 8px;
              border-radius: 4px;
              background: $NEW-PRIMARY1-50;
              &:last-child {
                background: $NEUTRAL-10;
                color: $NEUTRAL-100;
              }
              backdrop-filter: blur(5px);
              color: $NEUTRAL-10;
              font-size: 12px;
              font-weight: 500;
              &.newest {
                background-color: $SUCCESS-60;
              }
            }
          }
          > h4 {
            @include textEllipsis(1);
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
          }
          > p {
            @include textEllipsis(3);
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .right {
      @include flexCol(20px);
      .wrapDescription {
        @include flexRow(11px);
        align-items: center;
        > img {
          width: 151.67px;
          height: 151.67px;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
        }
        .descriptionContent {
          @include flexCol(8px);
          .category {
            @include flexRow(8px);
            .name {
              @include flexRow(8px);
              align-items: center;
              padding: 6px 8px;
              border-radius: 4px;
              background: $NEW-PRIMARY1-50;
              &:last-child {
                background: $NEW-PRIMARY2-BASE;
              }
              backdrop-filter: blur(5px);
              color: $NEUTRAL-10;
              font-size: 12px;
              font-weight: 500;
            }
          }
          > h4 {
            @include textEllipsis(1);
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            cursor: pointer;
          }
          > p {
            @include textEllipsis(3);
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .articleLanding {
    padding: 1rem;
    @media screen and (max-width: 500px) {
      .header {
        h2 {
          font-size: 18px;
        }
        .btnMore {
          padding: 4px 8px;
        }
      }
    }
    .mainContain {
      @include flexCol(1rem);
      .left {
        .articleNewest {
          .shadowInsetImg {
            height: 100px;
          }
          > img {
            width: 100%;
            @media screen and (max-width: 500px) {
              height: 300px;
            }
          }
          .descriptionContent {
            > p {
              @include textEllipsis(2);
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
      .right {
        .wrapDescription {
          > img {
            @media screen and (max-width: 500px) {
              width: 151px;
              height: 90px;
            }
          }
          .descriptionContent {
            row-gap: 2px;
            > h4 {
              font-size: 1rem;
              line-height: normal;
            }
            > p {
              @include textEllipsis(2);
              line-height: 1rem;
            }
          }
        }
      }
    }
  }
}
