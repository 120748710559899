@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .modalContainer {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: #fff;
    .modalHeader {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > :first-child {
        color: var(--Neutral-90, #404040);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
      > :last-child {
        color: var(--light-paragraph, #333);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        > span {
          font-weight: 700;
        }
      }
    }
    .modalBody {
      width: 100%;
    }
    .modalAction {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      > button {
        flex: 1;
      }
    }
  }
  .classHeader {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 20px;

    .thumbnail {
      width: 100%;
      // height: 100%;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .classDetailCard {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: #fff;
      min-width: 0;
      box-shadow: 0px 2px 24px -2px rgba(24, 39, 75, 0.12),
        -3px 4px 4px -2px rgba(24, 39, 75, 0.13);
      .divider {
        height: 1px;
        width: 100%;
        background: #ededed;
      }
      .cardHead {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .title {
          > :first-child {
            color: $NEUTRAL_100;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
          & > :last-child {
            > img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            > p {
              color: #000;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            display: flex;
            gap: 4px;
          }
        }
        .settings {
          display: flex;
          gap: 4px;
          .status {
            background: #ddf9a4;
            display: flex;
            width: 112px;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 17px;
            > p {
              color: $NEUTRAL_90;
              text-align: center;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px; /* 133.333% */
            }
          }
          > :last-child {
            color: $NEUTRAL_40;
            cursor: pointer;
          }
        }
      }

      .cardBody {
        display: flex;
        justify-content: space-between;
        width: 100%;
        row-gap: 16px;
        .label {
          width: 159px;
          display: flex;
          flex-direction: column;
          & > :first-child {
            color: $NEUTRAL_60;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
          & > :last-child {
            color: #000;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
          }
        }
      }
      .linkWrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        .switchWrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          > :last-child {
            color: $NEUTRAL-60;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
        .linkContainer {
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px solid $NEUTRAL-30;
          background: $NEUTRAL-20;
          flex: 1;
          min-width: 0;
          > :first-child {
            padding: 8px 12px;
            min-width: 0;
            flex: 1;
            > p {
              overflow: hidden;
              color: $NEUTRAL-70;
              text-overflow: ellipsis;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              white-space: nowrap;
              width: 100%;
            }
          }
          > :last-child {
            padding: 8px;
            border-radius: 0px 8px 8px 0px;
            border-left: 1px solid $NEUTRAL-30;
            background: var(--New-Primary-2-Primary-10, #f4eff6);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .productCodeWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .productCode {
          width: 159px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          .leftSection {
            > :first-child {
              display: flex;
              gap: 2px;
              align-items: center;
              > :first-child {
                color: $NEUTRAL_60;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
          > :last-child {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
      }
    }
  }
  .accordionHead {
    display: flex;
    align-items: center;
    gap: 12px;
    > :first-child {
      border-radius: 8px;
      border: 1px solid $NEW-PRIMARY2-BASE;
      background: $NEW-PRIMARY2-10;
      padding: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .accordionList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .scheduleSection {
    > :first-child {
      color: $NEUTRAL_100;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
      margin-bottom: 12px;
    }

    .scheduleBody {
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      flex-wrap: wrap;
      .scheduleCard {
        border-radius: 8px;
        border: 1px solid $NEW_PRIMARY2_BASE;
        background: #fff;
        text-align: center;
        align-self: stretch;
        .cardHead {
          padding: 8px 16px;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          border-bottom: 1px solid $NEW_PRIMARY2_BASE;
          > p {
            color: $NEW-PRIMARY2-BASE;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
        }
        .cardBody {
          display: flex;
          padding: 12px;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          > :first-child {
            > :first-child {
              color: $NEUTRAL-100;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px; /* 150% */
            }
            > :last-child {
              color: $NEUTRAL-100;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
            }
          }
          > :last-child {
            display: flex;
            gap: 4px;
            align-items: center;
          }
          .divider {
            background: #ededed;
            width: 145.6px;
            height: 1px;
          }
        }
      }
    }
  }
  .sessionBody {
    margin-top: 16px;
    width: 100%;
    .divider {
      width: 100%;
      height: 1px;
      background: $NEUTRAL_40;
    }
    .sessionContainer {
      max-height: 400px;
      padding-block: 16px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      gap: 16px;
      .sessionDesc {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .labelDesc {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        > p {
          color: $NEUTRAL_70;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        > :last-child {
          color: $NEUTRAL_90;
        }
        > :first-child {
          width: 110px;
          flex-shrink: 0;
        }
        .subjectFileDesc {
          display: flex;
          padding: 8px;
          align-items: center;
          border-radius: 12px;
          border: 1px solid $NEUTRAL_40;
          gap: 12px;
          width: 100%;
          .rightSection {
            > :first-child {
              color: $NEUTRAL_70;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            .link {
              color: $NEW-PRIMARY2-BASE;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px; /* 125% */
              text-decoration-line: underline;
              cursor: pointer;
            }
          }
        }
      }
      .subject {
        margin-top: 16px;
        > :first-child {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
          margin-bottom: 8px;
        }
        .matterDetail {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }
  .curriculumSection {
    > :first-child {
      color: $NEUTRAL_100;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
      margin-bottom: 12px;
    }
  }
}
