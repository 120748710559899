@use "theme/variables.scss" as *;

.wrapperModalVerifEmail {
  background-color: $NEUTRAL-10;
  padding: 24px;
  border-radius: 12px;
  max-width: 483px;
  width: 100%;
  margin-inline: 1rem;
  .firstDialog,
  .inputEmail {
    @include flexCol(1rem);
    > h2 {
      color: $NEUTRAL-90;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    > p {
      color: #000;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .inputEmail {
    .rowButton {
      width: 100%;
      @include flexRow(12px);
      align-items: center;
      > .btn {
        width: 100%;
      }
    }
    .between {
      @include flexBetween();
      > span {
        color: $DANGER_MAIN;
        font-size: 14px;
      }
      .btnChangeNumber {
        width: fit-content;
        color: $NEW-PRIMARY2-BASE;
        text-decoration: underline;
      }
    }
    .inputOTP {
      height: 70px !important;
    }
  }
  .successDialog {
    @include flexCol(1rem);
    align-items: center;
    width: 100%;
    h1 {
      color: $NEUTRAL-90;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    > img[alt="successBadge"] {
      width: 100px;
      height: 99.402px;
      flex-shrink: 0;
    }
    .btn {
      width: 100%;
    }
  }
}
