@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  display: flex;
  max-width: 1216px;
  max-height: 720px;
  width: 80vw;
  height: 80vh;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  min-height: 0;
  .inputWithLabel {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    > .label {
      color: $NEUTRAL-90;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      > span {
        color: $DANGER_MAIN;
      }
    }
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .headerText {
      > :last-child {
        color: $NEUTRAL-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      > :first-child {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }
    }
    > :last-child {
      cursor: pointer;
    }
  }
  .modalInput {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .select {
      height: 46px;
      > span {
        font-size: 16px;
      }
    }

    .inputBottomContainer {
      display: flex;
      gap: 24px;
      .flex {
        flex: 1;
        display: flex;
        gap: 12px;
        align-items: flex-end;
        > * {
          flex: 1;
        }
      }
    }
  }
  .scrollWrapper{
    overflow: auto;
    width: 100%;
    height: 100%;
    min-height: 0;

  }
  .content {
    width: 100%;
    // height: 80%;
    flex: 1;
    min-height: 0;
    // overflow: auto;
    margin-right: -24px;
    padding-right: 24px;
    margin-top: 20px;
    .contentHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      > :first-child {
        color: $NEUTRAL-100;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
      > :last-child {
        display: flex;
        align-items: center;
        gap: 8px;
        > span {
          color: $NEUTRAL-90;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 42px;
      margin-right: -24px;
      .addClass {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #ededed;
        position: relative;
        > :last-child {
          position: absolute;
          background: #ffffff;
        }
      }
    }
    .ClassCardInput {
      display: flex;
      gap: 12px;
      align-items: center;
      > * {
        flex-shrink: 0;
      }
      > :last-child {
        display: flex;
        width: 24px;
        height: 82px;
        padding: 42px 0px 16px 0px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
      }
      > :nth-child(2) {
        flex: 1;
      }
      .quotaInput {
        width: 250px;
        position: relative;
        > :last-child {
          display: flex;
          align-items: center;
          position: absolute;
          gap: 8px;
          padding-top: 6px;
          > span {
            color: #000;
            /* text-med/regular */
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px; /* 125% */
          }
        }
      }
      > .imageWrapper {
        width: 100px;
        height: 80px;
        > .thumbnail {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 1px solid $NEUTRAL-30;
          border-radius: 8px;
        }
        .imagePlaceholder {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--Neutral-30, #ededed);
        }
      }
    }
  }
  .modalAction {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    .deleteButton {
      flex: 1;
    }
  }
}
