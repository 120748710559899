@import "../../../theme/variables.scss";

.container {
  height: calc(100vh - 78px);
  // overflow-y: clip;
  position: relative;
  z-index: 0;

  >img {
    object-fit: contain;
    position: absolute;
    z-index: 0;

    &.leftClay {
      width: 650px;
      right: 60%;
      top: 40%;
      filter: blur(3px);
      transform: rotate(30deg);
      -webkit-animation: up-and-down 10s linear infinite;
      -moz-animation: up-and-down 10s linear infinite;
      animation: up-and-down 10s linear infinite;


    }

    &.rightClay {
      width: 650px;
      left: 70%;
      bottom: 23%;
      filter: blur(3px);
      -webkit-animation: spin 20s linear infinite;
      -moz-animation: spin 20s linear infinite;
      animation: spin 20s linear infinite;
      z-index: 0;

    }

  }

  .cardWrapper {
    height: calc(100vh - 78px);
    max-width: 1440px;
    width: 100vw;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include padding(32, 16, 16px);
    position: absolute;
    .card {
      width: 891px;
      height: 550px;
      max-height: 70vh;
      max-width: calc(100vw - 64px);
      backdrop-filter: blur(12.850000381469727px);
      @include padding-block(64, 16, 16px);
      @include padding-inline(32, 16, 16px);
      display: flex;
      align-items: center;
      @include gap(40, 24, 24px);

      >img {
        @include width(315, 150, 150px);
        object-fit: contain;
      }

      .main {
        display: flex;
        flex-direction: column;
        @include gap(24, 16, 16px);
        flex-grow: 1;

        .title {
          display: flex;
          flex-direction: column;
          gap: 8px;

          >h3 {
            font-family: $BASE-FONT-FAMILY;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
          }

          >span {
            font-family: $BASE-FONT-FAMILY;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
            color: $NEUTRAL-80;
          }
        }

        >p {
          display: flex;
          align-items: center;
          gap: 8px;

          >span {
            color: $NEUTRAL-60;
            font-family: $BASE-FONT-FAMILY;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 114.286%;

          }

          .switchButton {
            padding: 0;
          }

        }

        >form {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .remark {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-top: 8px;

            >span {
              color: $NEUTRAL-50;
              font-family: $BASE-FONT-FAMILY;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
            }
          }

          .fields {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          .resendWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            >button {
              padding: 0;
            }

            >span {
              font-family: $BASE-FONT-FAMILY;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 142.857%;
            }
          }
        }

      }

    }
  }



}

@media screen and (max-width: 768px) {
  .container {

    >img {

      &.leftClay {
        right: 10% !important;
      }

      &.rightClay {
        left: 10% !important;
      }
    }

    .cardWrapper {
      padding: 16px;
      align-items: flex-start;
    }

    .card {
      flex-direction: column;
      overflow: clip;
      max-height: none !important;
      max-width: calc(100vw - 32px) !important;
      height: auto!important;


    }
  }
}

@-moz-keyframes spin {

  0%,
  100% {
    -moz-transform: rotate(15deg);
  }

  50% {
    -moz-transform: rotate(60deg);
  }

}

@-webkit-keyframes spin {

  0%,
  100% {
    -webkit-transform: rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(60deg);
  }

}

@keyframes spin {

  0%,
  100% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
}

@-moz-keyframes up-and-down {

  0%,
  100% {
    top: 40%;
  }

  50% {
    top: 50%;
  }
}

@-webkit-keyframes up-and-down {

  0%,
  100% {
    top: 40%;
  }

  50% {
    top: 50%;
  }
}

@keyframes up-and-down {

  0%,
  100% {
    top: 40%;
  }

  50% {
    top: 50%;
  }
}