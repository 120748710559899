$NEUTRAL-10: #ffffff;
$NEUTRAL-20: #fafafa;
$NEUTRAL-30: #ededed;
$NEUTRAL-40: #e0e0e0;
$NEUTRAL-50: #c2c2c2;
$NEUTRAL-60: #9e9e9e;
$NEUTRAL-70: #757575;
$NEUTRAL-80: #616161;
$NEUTRAL-90: #404040;
$NEUTRAL-100: #0a0a0a;

$NEW-PRIMARY1-100: #05040b;
$NEW-PRIMARY1-90: #080712;
$NEW-PRIMARY1-80: #0f0f24;
$NEW-PRIMARY1-70: #171636;
$NEW-PRIMARY1-60: #1e1d49;
$NEW-PRIMARY1-BASE: #26255b;
$NEW-PRIMARY1-50: #3c3b91;
$NEW-PRIMARY1-40: #5d5cbd;
$NEW-PRIMARY1-30: #9392d3;
$NEW-PRIMARY1-20: #c9c9e9;
$NEW-PRIMARY1-10: #e6e6f5;

$NEW-PRIMARY2-100: #0e0910;
$NEW-PRIMARY2-90: #1d1221;
$NEW-PRIMARY2-80: #3c2645;
$NEW-PRIMARY2-70: #593866;
$NEW-PRIMARY2-60: #764a87;
$NEW-PRIMARY2-BASE: #9360a8;
$NEW-PRIMARY2-50: #a87eb9;
$NEW-PRIMARY2-40: #be9fcb;
$NEW-PRIMARY2-30: #d5c0dd;
$NEW-PRIMARY2-20: #e9deed;
$NEW-PRIMARY2-10: #f4eff6;

$NEW-PRIMARY3-100: #03141b;
$NEW-PRIMARY3-90: #072837;
$NEW-PRIMARY3-80: #0d506d;
$NEW-PRIMARY3-70: #1479a4;
$NEW-PRIMARY3-60: #1aa1db;
$NEW-PRIMARY3-BASE: #1aa1db;
$NEW-PRIMARY3-50: #6dc7ee;
$NEW-PRIMARY3-40: #92d5f2;
$NEW-PRIMARY3-30: #b6e3f6;
$NEW-PRIMARY3-20: #dbf1fb;
$NEW-PRIMARY3-10: #edf8fd;

$DANGER_MAIN: #ff3e13;

$DANGER-90: #64050b;
$DANGER-80: #790908;
$DANGER-70: #96160d;
$DANGER-60: #b22a12;
$DANGER-50: #d1421a;
$DANGER-40: #e3794f;
$DANGER-30: #f1a073;
$DANGER-20: #fac8a3;
$DANGER-10: #fce8d0;

$SUCCESS-90: #275f06;
$SUCCESS-80: #347109;
$SUCCESS-70: #498e0f;
$SUCCESS-60: #60aa15;
$SUCCESS-50: #7ac61c;
$SUCCESS-40: #a3dc4f;
$SUCCESS-30: #c1ed74;
$SUCCESS-20: #ddf9a4;
$SUCCESS-10: #f0fdd2;

$NEUTRAL-OVERLAY: #00000080;

$GRADIENT: linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%);
$COLOR-GRADIENT-2: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);

$BASE-FONT-FAMILY: Poppins, sans-serif;

$BOX-SHADOW: 0px 6px 24px 0px rgba(158, 158, 158, 0.25);

@mixin width($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-size($max, $min, $minpx) {
  font-size: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin line-height($max, $min, $minpx) {
  line-height: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin fluid-image-size($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
  height: 100%;
}

@mixin top($max, $min, $minpx) {
  top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin gap($max, $min, $minpx) {
  gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding($max, $min, $minpx) {
  padding: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-top($max, $min, $minpx) {
  padding-top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-right($max, $min, $minpx) {
  padding-right: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-bottom($max, $min, $minpx) {
  padding-bottom: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-left($max, $min, $minpx) {
  padding-left: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-block($max, $min, $minpx) {
  padding-block: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin padding-inline($max, $min, $minpx) {
  padding-inline: calc(
    $minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390))
  );
}

@mixin flexRow($gap: 0) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flexCol($rowGap: 0) {
  display: flex;
  flex-direction: column;
  row-gap: $rowGap;
}

@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
}

@mixin flexColumnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin textEllipsis($lineClamp: 1) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  line-clamp: $lineClamp;
  -webkit-line-clamp: $lineClamp;
}

@mixin buttonOutlineGradient() {
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px solid #795ec6;
  width: fit-content;
  cursor: pointer;
  &:hover {
    border: 2px solid $NEW-PRIMARY2-BASE;
    > span {
      background: $NEW-PRIMARY2-BASE;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  > span {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
