@use "theme/variables.scss" as *;

@keyframes rotates {
  0%,
  100% {
    transform: rotate(40deg);
  }

  50% {
    transform: rotate(50deg);
  }
}

@keyframes up-and-down-arrow {
  0%,
  100% {
    top: 0%;
  }

  50% {
    top: 4%;
  }
}

.sectionMainArticlePage {
  @include flexCol();
  width: 100%;
  align-items: center;
  position: relative;
  .header {
    @include flexCol(12px);
    padding-top: 43px;
    align-items: center;
    width: 100vw;
    height: 300px;
    background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
    color: $NEUTRAL-10;
    > h1 {
      font-size: 48px;
      font-weight: 600;
      line-height: 57px;
      z-index: 2;
    }
    > p {
      font-size: 14px;
      line-height: 20px;
      z-index: 2;
    }
    .iconActive {
      transform: rotate(90deg);
      cursor: pointer;
      position: relative;
      animation: up-and-down-arrow 2s infinite ease-out;
      &:hover {
        animation-play-state: paused;
      }
    }

    .rightClay {
      position: absolute;
      filter: blur(3px);
      z-index: 1;
      top: -120px;
      width: 451.289px;
      height: 451.289px;
      flex-shrink: 0;
      right: -150px;
      animation: rotates 4s infinite linear;
      &:hover {
        filter: blur(0);
      }
    }
  }
  .mainContain {
    z-index: 2;
    position: relative;
    top: -70px;
    width: 90%;
    max-width: 1440px;
    border-radius: 8px;
    @include flexCol(40px);
    .wrapPagination {
      @media screen and (min-width: 1024px) {
        width: 70%;
      }
    }
    .highlightArticle {
      width: 100%;
      @include flexRow(8px);
      height: 470px;
      > div {
        width: 60%;
        height: 470px;
        border-radius: 8px;
        background-color: $NEUTRAL-30;
      }
      > aside {
        width: 40%;
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
        > div {
          @include flexRow(0);
          align-items: center;
          position: relative;
          box-shadow: 0 2px 6px #00000028;
          background-color: $NEUTRAL-30;
          border-radius: 8px;
          .shadowInsetImg {
            position: absolute;
            height: 80px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            bottom: 0;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
            filter: blur(30px);
          }
          > img {
            object-fit: cover;
            width: 100%;
            height: 231px;
            border-radius: 8px;
          }
          .wrapperText {
            position: absolute;
            @include flexCol(8px);
            bottom: 0;
            padding: 12px;
            .category {
              @include flexRow(8px);
              .name {
                @include flexRow(8px);
                align-items: center;
                padding: 6px 8px;
                border-radius: 4px;
                background: $NEW-PRIMARY1-50;
                &:last-child {
                  background: $NEUTRAL-10;
                  color: $NEUTRAL-100;
                }
                backdrop-filter: blur(5px);
                color: $NEUTRAL-10;
                font-size: 12px;
                font-weight: 500;
                &.newest {
                  background-color: $NEW-PRIMARY2-BASE;
                }
              }
            }
            > strong {
              @include textEllipsis(2);
              font-size: 1rem;
              color: $NEUTRAL-10;
              z-index: 1;
              cursor: pointer;
            }
          }
        }
      }
      .articleNewest {
        height: 100%;
        width: 60%;
        display: flex;
        position: relative;
        box-shadow: 0 2px 6px #00000028;

        .shadowInsetImg {
          position: absolute;
          height: 200px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          bottom: 0;
          box-shadow: 0 -16px 10px rgba(0, 0, 0, 0.1);
          filter: blur(30px);
        }
        > img {
          width: 100%;
          height: 100%;
          flex-shrink: 0;
          object-fit: cover;
          border-radius: 8px;
        }
        .descriptionContent {
          position: absolute;
          padding: 16px;
          bottom: 0;
          color: $NEUTRAL-10;
          @include flexCol(4px);
          .category {
            @include flexRow(8px);
            .name {
              @include flexRow(8px);
              align-items: center;
              padding: 6px 8px;
              border-radius: 4px;
              background: $NEW-PRIMARY1-50;
              &:last-child {
                background: $NEUTRAL-10;
                color: $NEUTRAL-100;
              }
              backdrop-filter: blur(5px);
              color: $NEUTRAL-10;
              font-size: 12px;
              font-weight: 500;
              &.newest {
                background-color: $SUCCESS-60;
              }
            }
          }
          > h4 {
            @include textEllipsis(1);
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
          }
          > p {
            @include textEllipsis(2);
            font-size: 14px;
            line-height: 20px;
          }
          .btnMore {
            width: fit-content;
            // border: unset;
            // padding: unset;
            color: #fff;
          }
        }
      }
    }
    .listArticles {
      @include flexRow(12px);
      width: 100%;
      position: relative !important;
      .left {
        @include flexCol(1rem);
        width: 70%;
        .headerListBetween {
          @include flexBetween();
          align-items: center;

          > h1 {
            color: $NEUTRAL-100;
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
          }
          > span {
            color: $NEUTRAL-70;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .filterArticle {
          @include flexRow(12px);
          flex-wrap: wrap;
          .tab {
            padding: 12px 16px;
            border-radius: 60px;
            border: 2px solid $NEUTRAL-30;
            background: $NEUTRAL-10;
            cursor: pointer;
            transition: 0.2s;
            white-space: nowrap;
            color: $NEUTRAL-70;
            &.isLoad {
              background-color: $NEUTRAL-30;
              color: transparent;
            }
          }
          .tab:hover {
            box-shadow: 0px 3px 6.8px 0px rgba(0, 0, 0, 0.12);
          }

          .tab[is-selected="true"] {
            border: 2px solid $NEUTRAL-10;
            box-shadow: 0px 3px 6.8px 0px rgba(0, 0, 0, 0.12);
            background: linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%);
            box-shadow: inset -0px -2px 0px 0px #3c3b91;
            color: $NEUTRAL-10;
          }
        }
        .cards {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
          gap: 12px;
        }
      }
      .rightSticky {
        margin-top: 60px;
        @include flexCol(1rem);
        width: 30%;
        min-width: 330px;
        padding: 12px;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        @include flexCol(10px);
        height: fit-content;
        position: sticky;
        top: 90px;
        > h4 {
          color: $NEUTRAL-100;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .sectionMainArticlePage {
    @media screen and (max-width: 768px) {
      .header {
        text-align: center;
        row-gap: 8px;
        padding-inline: 1rem;
        height: calc(295px - 56px);
        > h1 {
          font-size: 24px;
          line-height: 30px;
        }
        > p {
          font-size: 12px;
        }
        .iconActive {
          display: none !important;
        }
      }
    }
    .mainContain {
      top: -100px;
      row-gap: 32px;
      .highlightArticle {
        width: 100%;
        height: 100%;
        @include flexCol(8px);
        > div {
          width: 100% !important;
        }
        > aside {
          width: 100%;
          > div {
            > img {
              height: 190px;
            }
            .wrapperText {
              .category {
                @include flexCol(4px);
                .name {
                  width: fit-content !important;
                }
              }
              > strong {
                font-size: 14px;
              }
            }
          }
        }
        .articleNewest {
          > img {
            height: 352px;
          }
          .descriptionContent {
            .btnMore {
              border: unset;
              padding-inline: unset;
            }
          }
        }
      }
    }
    .listArticles {
      display: flex;
      flex-direction: column !important;
      height: 100%;
      .rightSticky {
        position: static !important;
      }
      .left {
        width: 100% !important;
        .headerListBetween {
          width: 100%;
          > h1 {
            font-size: 24px !important;
            line-height: normal;
          }
          > span {
            font-size: 14px;
          }
        }
        .filterArticle {
          @media screen and (max-width: 768px) {
            gap: 8px !important;
            font-size: 14px !important;
          }
        }
      }
      .rightSticky {
        width: 100% !important;
        margin-top: 1rem !important;
      }
    }
  }
}
