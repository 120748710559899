@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: clip;
  // height: 100vh;
  // background-color: $NEUTRAL-20;

  &.isGradientBg {
    background: $COLOR-GRADIENT-2;

    .contentWrapper {
      overflow-x: visible !important;
      overflow: clip;
    }
  }
  .navbarLink {
    color: var(--Neutral-60, #9e9e9e);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    > li {
      text-decoration: none;
      display: inline;
    }
  }

  .topWrapper {
    // padding: 14px 32px;
    @include padding-block(14, 12, 12px);
    @include padding-inline(32, 16, 16px);
    background-color: $NEUTRAL-10;
    box-shadow: $BOX-SHADOW;
    position: sticky;
    top: 0;
    z-index: 3;
    height: 78px;
    display: flex;
    align-items: center;

    .topContent {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      margin: auto;
      @include gap(32, 16, 16px);

      .logo {
        display: flex;
        align-items: center;
        @include gap(12, 9, 9px);
        cursor: pointer;

        > img {
          @include fluid-image-size(40, 30, 30px);
          object-fit: contain;
        }

        > span {
          font-family: $BASE-FONT-FAMILY;
          @include font-size(24, 18, 18px);
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $NEW-PRIMARY1-BASE;
        }
      }

      .topNavs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        gap: 24px;
        margin-left: 42px;

        a li {
          transition: 0.3s;
          &:hover {
            background: $GRADIENT;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        > p {
          color: #000;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: none;
        }
      }

      .right {
        display: flex;
        align-items: center;
        @include gap(32, 16, 16px);

        .authButton {
          padding: 8px 16px !important;
        }

        .actions {
          display: flex;
          align-items: center;
          @include gap(24, 8, 8px);

          > button {
            display: grid;
            place-items: center;
            background-color: transparent;
            border: none;
            color: $NEUTRAL-50;
          }
        }

        .profileButton {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $NEUTRAL-50;
          position: relative;
          cursor: pointer;

          .dropdownWrapper {
            z-index: 1;
            position: absolute;
            top: calc(100% + 8px);
            right: 0;
            cursor: auto;
          }
        }
      }
    }
  }

  .contentWrapper {
    // height: 100px;
    flex-grow: 1;
    width: 100vw;
    max-width: 1440px;
    margin: auto;
    overflow-x: clip;
    // overflow-y: auto;
    // background-color: $NEUTRAL-10;
    min-height: calc(100vh - 78px);
    > div {
      min-height: calc(100vh - 78px);
    }

    &.noMaxWidth {
      max-width: 100vw;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .navbarLink {
      display: none;
    }
    .topNavs {
      > p {
        display: block !important;
      }
    }
  }
}
