@import "../../../theme/variables.scss";

.container {
  max-width: 1440px;
  margin-inline: auto;
  .backButton {
    padding-left: 32px;
    padding-top: 16px;
    padding-bottom: 20px;
  }

  .sectionWrapper {
    padding-inline: 111px;
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 50px;
    margin-bottom: 100px;

    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 24px;
      > :first-child {
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 24px;
      }
      .headerSection {
        > :first-child {
          color: #323232;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px; /* 120% */
          margin-bottom: 18px;
        }
        .classThumbnail {
          display: flex;
          gap: 16px;
          align-items: center;
          .classDesc {
            display: flex;
            flex-direction: column;
            > :first-child {
              color: var(--Neutral-90, #404040);
              // font-size: 24px;
              font-weight: 500;
              line-height: 24px; /* 100% */
              @include font-size(24, 14, 14px);
            }
            > :nth-child(2) {
              padding-top: 11px;
              padding-bottom: 8px;
              color: var(--Neutral-80, #616161);
              @include font-size(16, 12, 12px);

              font-weight: 400;
              line-height: 20px; /* 125% */
            }
            .pricing {
              display: flex;
              align-items: center;
              gap: 8px;
              > .discount {
                display: flex;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: #dbdbdb;
                > p {
                  color: #908f8f;
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 20px; /* 153.846% */
                }
              }
              > :nth-child(2) {
                color: var(--Danger-Danger-60, #b22a12);
                font-size: 16px;
                font-weight: 600;
                line-height: 20px; /* 125% */
                text-decoration-line: line-through;
              }
              .totalPrice {
                color: var(--New-Primary-2-Primary-Base, #9360a8);
                font-size: 20px;
                font-weight: 500;
                line-height: 24px; /* 120% */
              }
            }
          }
          .thumbnailImage {
            height: 91px;
            width: 88px;
            > img {
              width: 88px;
              height: 91px;
              flex-shrink: 0;
              object-fit: contain;
            }
          }
        }
      }
      .batchSection {
        > :first-child {
          color: #323232;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
          margin-bottom: 12px;
        }
        .batchWrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .noBatch {
            border-radius: 8px;
            border: 2px solid var(--New-Primary-2-Primary-20, #e9deed);
            background: #f8f5ff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 16px 80px;
            gap: 15px;
            > img {
              width: 164px;
              height: 156px;
            }
            > p {
              color: var(--New-Primary-2-Primary-70, #593866);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 114.286% */
            }
          }
          .batch {
            display: flex;
            align-items: center;
            padding: 12px;
            gap: 12px;
            border-radius: 12px;
            border: 1px solid var(--Neutral-40, #e0e0e0);
            cursor: pointer;
            justify-content: space-between;
            .date {
              display: flex;
              align-items: center;
              gap: 12px;
              width: 100%;
              .batchDateWrapper {
                display: flex;
                flex: 1;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                .quota {
                  color: var(--Danger-Danger-60, #b22a12);
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 14px; /* 116.667% */
                }
              }
            }
          }
          .batch[is-selected="true"] {
            background: linear-gradient(#fff 0 0) padding-box,
              /*this is your grey background*/ $GRADIENT border-box;
            color: #313149;
            border: 2px solid transparent;
            border-radius: 12px;
          }
          .batch[is-disabled="true"] {
            border: 1px solid $NEUTRAL-40;
            color: $NEUTRAL-50;
            cursor: default;
            border-radius: 12px;
          }
        }
      }
    }
    .rightSection {
      height: 100%;
      position: relative;
      .cardWrapper {
        position: sticky;
        top: 100px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .promoSection {
          display: flex;
          padding: 16px 16px;
          flex-direction: column;
          border-radius: 12px;
          background: #fff;
          box-shadow: 0px 5px 12px 0px rgba(128, 128, 128, 0.3);
          width: 100%;

          .cardHeader {
            margin-bottom: 8px;

            > :first-child {
              color: #323232;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px; /* 125% */
              margin-bottom: 10px;
            }
            > :last-child {
              color: var(--Neutral-90, #404040);
              font-size: 12px;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
          }
          .inputButton {
            margin-top: 16px;
            margin-bottom: 16px;
            > button {
              width: 100%;
            }
          }
          .cardFooter {
            color: var(--Neutral-90, #404040);
            font-size: 12px;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
          }
        }
        .checkoutSection {
          display: flex;
          padding: 16px 16px;
          flex-direction: column;
          border-radius: 12px;
          background: #fff;
          box-shadow: 0px 5px 12px 0px rgba(128, 128, 128, 0.3);
          width: 100%;
          gap: 20px;
          .cardHeader {
            > p {
              color: #323232;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px; /* 125% */
            }
          }
          .cardBody {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .divider {
              height: 1px;
              background: #000;
              width: 100%;
            }
            .priceDetail {
              display: flex;
              justify-content: space-between;
              align-items: center;
              > :first-child {
                overflow: hidden;
                color: #7c7c7c;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                // -webkit-line-clamp: 1;
                line-clamp: 1;
                width: 181px;
                text-wrap: nowrap;
              }
            }
            > :last-child {
              > :last-child {
                color: #323223;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px; /* 125% */
              }
            }
          }
          .cardAction {
            > button {
              width: 100%;
            }
          }
          .errorMessage {

            align-items: center;
            gap: 8px;

            padding: 8px;
            align-self: stretch;
            border-radius: 8px;
            background: var(--Danger-Danger-10, #fce8d0);
            > :first-child {
              flex-shrink: 0;
            }
            > p {
              color: var(--Danger-Danger-60, #b22a12);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
        }
      }
    }
  }
  .videoSection {
    grid-template-columns: 1fr;
    .rightSection {
      .cardWrapper {
        flex-direction: row;
      }
    }
  }
  .modalContainer {
    .modalBody {
      position: fixed;
      background-color: #ffff;
      width: 100vw;
      padding-bottom: 50px;
      padding-top: 8px;
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.03);
      padding-inline: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      transition: 1s;
      .line {
        width: 42px;
        height: 5px;
        background: #b4b7ba;
        border-radius: 8px;
      }
      .modalContent {
        width: 100%;
      }
      .cardHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        > :first-child {
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
      .cardBody {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .divider {
          height: 1px;
          background: #000;
          width: 100%;
        }
        .priceDetail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > :first-child {
            overflow: hidden;
            color: #7c7c7c;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
            // -webkit-line-clamp: 1;
            line-clamp: 1;
            width: 181px;
            text-wrap: nowrap;
          }
        }
        > :last-child {
          > :last-child {
            color: #323223;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px; /* 125% */
          }
        }
      }
    }
    .modalBody[is-open="true"] {
      bottom: 0;
    }
    .modalBody[is-open="false"] {
      bottom: -250px;
    }
  }
  .modalContainer[is-open="false"] {
    width: 0;
    height: 0;
  }
  .modalContainer[is-open="true"] {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 100;
  }
  .bottomNav {
    display: none;
    width: 100vw;
    padding: 8px 16px 16px 16px;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 0;
    background-color: #ffff;
    border-top: 1px solid $NEUTRAL-30;
    > :first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > :first-child {
        display: flex;
        flex-direction: column;
        > :first-child {
          color: #7c7c7c;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
        > :last-child {
          color: #323223;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
      }
      > :last-child {
        color: #5d5cbd;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    > :last-child {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 120px;
    .bottomNav {
      display: flex !important;
    }
    .sectionWrapper {
      grid-template-columns: 1fr;
      gap: 16px;
      padding-inline: 16px;
      margin-bottom: 50px;
    }
    .batchDateWrapper {
      flex-direction: column;
      align-items: flex-start !important;
      row-gap: 2px;
    }
    .classDesc {
      > :nth-child(2) {
        padding-top: 4px !important;
        padding-bottom: 8px !important;
      }
    }
    .checkoutSection {
      display: none !important;
    }
  }
}
