@use "theme/variables.scss" as *;
.wrapDescription {
  @include flexRow(11px);
  align-items: center;
  > img {
    width: 100px;
    height: 99.998px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }
  .descriptionContent {
    @include flexCol(0);
    flex-grow: 1;
    min-width: 0;
    .category {
      @include flexRow(8px);
      .name {
        @include flexRow(8px);
        align-items: center;
        padding: 6px 8px;
        border-radius: 4px;
        background: $NEW-PRIMARY1-50;
        &:last-child {
          background: $NEW-PRIMARY2-BASE;
        }
        backdrop-filter: blur(5px);
        color: $NEUTRAL-10;
        font-size: 12px;
        font-weight: 500;
        flex-shrink: 0;
      }
    }
    > h4 {
      @include textEllipsis(1);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
    }
    > p {
      @include textEllipsis(2);
      font-size: 12px;
      cursor: pointer;
      width: 100%;
    }
  }
}

.card {
  height: 378px;
  border-radius: 8px;
  @include flexCol();
  position: relative;
  background-color: $NEUTRAL-10;
  border: 1px solid $NEUTRAL-30;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 3rem);
  }

  &::before {
    content: "";
    width: 100%;
    height: 10px;
    background-color: $NEUTRAL-10;
    position: absolute;
    bottom: 0;
    filter: blur(16px);
    z-index: 3;
    transition: 0.6s ease-out;
  }
  &::after {
    content: "";
    width: 100%;
    height: 0;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    transition: 0.7s;
    bottom: 0;
  }
  &:hover {
    cursor: text;
    > img {
      filter: blur(3px);
    }
    &::before {
      bottom: 100%;
    }
    &::after {
      background-color: rgba(0, 0, 0, 0.4);
      height: 100%;
    }
    .wrapText {
      top: 30px;
      color: $NEUTRAL-10;
      z-index: 5;
      height: 100%;
      background-color: transparent;
      .uploadedAt {
        color: $NEUTRAL-10;
      }
      > p {
        @include textEllipsis(8);
      }
      .btnMore {
        @include flexRow(4px);
      }
    }
    .category {
      transition-delay: 0.5s;
      color: $NEUTRAL-100;
      background-color: $NEUTRAL-10;
    }
  }
  @media screen and (max-width: 768px) {
    scroll-snap-align: start;
    // max-width: 288px;
  }
  .viewColSkeleton {
    @include flexCol(8px);
    margin-top: 8px;
  }
  .category {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 12px;
    background-color: $NEW-PRIMARY1-50;
    color: $NEUTRAL-10;
    font-size: 12px;
    font-weight: 500;
    border-bottom-right-radius: 8px;
    z-index: 2;
  }
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .wrapText {
    @include flexCol(8px);
    padding: 1rem;
    color: $NEUTRAL-100;
    position: absolute;
    bottom: 0;
    background-color: $NEUTRAL-10;
    width: 100%;
    height: 128px;
    .uploadedAt {
      @include flexRow(4px);
      color: $NEUTRAL-60;
      font-size: 12px;
      font-weight: 500;
    }
    > span {
      @include textEllipsis(1);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    > p {
      @include textEllipsis(2);
      font-size: 14px;
    }
    .btnMore {
      display: none;
      cursor: pointer;
      align-items: center;
      position: absolute;
      bottom: 50px;
      z-index: 5;
    }
  }
}
