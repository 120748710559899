.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  & > :first-child {
    width: 200px;
    height: 200px;
  }
  & > :last-child {
    color: var(--New-Primary-2-Primary-Base, #9360a8);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
  }
}
