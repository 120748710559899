@import "../../../../theme/variables.scss";

.container {
  padding: 24px;
  .tabWrapper {
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 20px;
    gap: 12px;
  }

  .table {
    border-radius: 12px;
    border: 1px solid $NEUTRAL-40;
  }
  .doodleCard {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    h2 {
      color: $NEUTRAL-10;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      .span {
        font-weight: 600;
      }
    }
    p {
      color: $NEUTRAL-10;
      font-size: 14px;
      line-height: 20px;
      .span {
        font-style: italic;
      }
    }
  }
  .searchWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
    > :last-child {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .periodWrapper {
    display: flex;
    flex-direction: column;
    .period {
      display: flex;
      align-items: center;
      gap: 4px;
      > p {
        color: var(--Neutral-100, #3e4856);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
}
