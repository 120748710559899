@use "theme/variables.scss" as *;

.wrapperDetailArticleLayout {
  @include flexCol(1rem);
  padding: 12px 32px;
  .topBetweenMenu {
    @include flexBetween();
    .breadCrumbs {
      @include flexRow(8px);
      align-items: center;
      .iconHome {
        cursor: pointer;
        color: $NEW-PRIMARY2-BASE;
      }
      > span {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL-60;
        cursor: pointer;
        &:hover {
          color: $NEW-PRIMARY2-BASE;
        }
        &:last-child {
          color: $NEUTRAL-100;
          cursor: text;
        }
      }
    }
    .shareLink {
      @include flexRow(12px);
      > img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
  .mainDetailArticle {
    @include flexCol(32px);
    > img {
      width: 100%;
      height: 497px;
      object-fit: cover;
      border-radius: 8px;
    }
    .viewerRow {
      @include flexRow(20px);
      width: 100%;
      position: relative;
      @media screen and (max-width: 1024px) {
        @include flexCol(20px);
        .articleSelectionSticky {
          width: 100% !important;
        }
      }
      .wrapText {
        width: 70%;
        .titleAndDate {
          @include flexCol(8px);
          > h2 {
            color: $NEUTRAL-90;
            font-size: 24px;
            font-weight: 600;
            line-height: 30px;
            @include textEllipsis(2);
          }
          > div {
            @include flexRow(4px);
            align-items: center;
            > span {
              color: $NEUTRAL-70;
              font-size: 14px;
            }
          }
        }
        .description {
          margin-top: 24px;
          color: #000;
          font-size: 14px;
          line-height: 20px;
        }
        .textRenderer {
          white-space: pre-wrap;
          margin-top: 24px;
          > p {
            img {
              max-width: 100% ;
            }
          }
        }
      }
      .articleSelectionSticky {
        width: 30%;
        @include flexCol(10px);
        position: sticky;
        height: fit-content;
        top: 90px;
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;
        padding: 12px;
        > h2 {
          color: $NEUTRAL-100;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
        }
      }
    }
    .otherArticles {
      @include flexCol(12px);
      > h1 {
        color: $NEUTRAL-100;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }
      .wrapCards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(295px, 4fr));
        gap: 12px;
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapperDetailArticleLayout {
    padding-inline: unset;
    .topBetweenMenu {
      padding-inline: 16px;
      @include flexCol(8px);
      .breadCrumbs {
        > span {
          &:last-child {
            @include textEllipsis(1);
          }
        }
      }
    }
    .mainDetailArticle {
      row-gap: 1rem;
      .viewerRow {
        .wrapText {
          .titleAndDate {
            > h2 {
              font-size: 16px;
              line-height: normal;
            }
          }
          .textRenderer {
            margin-top: 1rem;
            > p {
              img {
                max-width: 100% ;
              }
            }
          }
        }
      }
      > img {
        margin-inline: 16px;
        height: 225px;
        width: 90%;
      }
      .viewerRow {
        padding-inline: 16px;
        @include flexCol(16px);
        .wrapText {
          width: 100%;
        }
        .articleSelectionSticky {
          margin-top: 16px;
          width: 100%;
          border: unset;
          padding: unset;
        }
      }
      .otherArticles {
        > h1 {
          padding-inline: 16px;
          font-size: 20px;
          line-height: normal;
        }
        .wrapCards {
          display: flex;
          overflow: auto;
          scroll-snap-type: x mandatory;
          padding-inline: 16px;
          scroll-padding-left: 12px;
          margin-bottom: unset;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}
