@import "../../../../theme/variables.scss";

.container {
  display: flex;
  width: 606px;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  background: $NEUTRAL-10;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > :first-child {
      color: #000;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px; /* 130% */
    }
    > :last-child {
      cursor: pointer;
    }
  }
  .content {
    > :first-child {
      margin-bottom: 8px;
      color: $NEUTRAL-80;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
    }
  }
  .actionWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
  }
}
