@import "../../../theme/variables.scss";

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-height: 300px;
  .wrapImage {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 50px;
      left: 30%;
      width: 100px;
      height: 16px;
      flex-shrink: 0;
      border-radius: 50%;
      background: linear-gradient(179deg, #686868 0.81%, #565656 138.54%);
      z-index: 2;
      filter: blur(16px);
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 16px;
      left: -20px;
      width: 284px;
      height: 65px;
      flex-shrink: 0;
      border-radius: 50%;
      background: linear-gradient(179deg, #f4eff6 0.81%, #e9e7ff 138.54%);
      z-index: 1;
      backdrop-filter: blur(5px);
    }
  }
  .emptyIcon {
    position: relative;
    width: 235px;
    height: 235px;
    flex-shrink: 0;
    z-index: 2;
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > h3 {
      color: $NEUTRAL-100;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    > span {
      font-family: $BASE-FONT-FAMILY;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_60;
    }
  }
}
