// @import "../../../theme/variables.scss";

// .spinner {
//   aspect-ratio: 1;
//   border-radius: 50%;
//   border: 5px solid $NEUTRAL-40;
//   border-bottom-color: $NEW-PRIMARY2-BASE;
//   border-radius: 50%;
//   display: inline-block;
//   box-sizing: border-box;
//   animation: rotation 1s linear infinite;
// }

// @keyframes rotation {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

.loader {
  object-fit: contain;
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
