@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .title {
    font-family: $BASE-FONT-FAMILY;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL-100;
  }

  .inputWrapper {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    gap: 8px;
    width: 100%;

    .each {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      position: relative;

      > input {
        // @include width(53, 47, 47px);
        width: 100%;
        height: 44px;
        text-align: center;

        border: 1px solid $NEUTRAL-50;
        border-radius: 8px;
        padding: 12px;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        font-family: $BASE-FONT-FAMILY;

        &:focus {
          outline: none;
          border: 1px solid $NEW-PRIMARY2-BASE;
        }
      }

      .before,
      .after {
        width: 15px;
        height: 1px;
        background-color: #e9ecef;
        // position: absolute;
      }
    }
  }
}
