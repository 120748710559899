@import "../../../theme/variables.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $BASE-FONT-FAMILY;
  scroll-margin-top: 70px;
  .wrapperClass {
    padding: 32px;
    width: 1440px;
    max-width: 100vw;

    main {
      .tabs {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .button {
          display: flex;
          gap: 6px;
          align-items: center;
          cursor: pointer;
          text-align: center;

          p {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            background: $COLOR-GRADIENT-2;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          img {
            width: 1rem;
            height: 1rem;
          }
        }
        .overFlowTabHeader {
          overflow: auto;
        }
        .overFlowTabHeader::-webkit-scrollbar {
          display: none;
        }
        .overFlowTabHeader {
          scrollbar-width: thin;
          scrollbar-color: $NEUTRAL-50 transparent;
        }
        .overFlowTabHeader::-webkit-scrollbar-track {
          background-color: transparent;
        }
        .overFlowTabHeader::-webkit-scrollbar-thumb {
          background-color: $NEUTRAL-30;
        }
      }
      .tabs[is-mobile="true"] {
        display: flex;
        flex-direction: column;
      }

      .mainContain {
        display: flex;
        width: 100%;
        padding-block: 32px;
        gap: 24px;
        .detailClass {
          width: 333px;
          height: max-content;
          position: sticky;
          top: 78px; // height topBar
          .typeClass {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 14px;
            img {
              width: 193px;
              height: 193px;
              flex-shrink: 0;
            }

            h1 {
              color: $NEUTRAL-90;
              text-align: center;
              font-size: 32px;
              font-weight: 600;
              line-height: 40px;
            }

            p {
              color: $NEUTRAL-60;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }

        .wrapperCard {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          // display: flex;
          // flex-wrap: wrap;
          // grid-template-columns: repeat(auto-fill, minmax(294px, 1fr));
          row-gap: 32px;
          gap: 24px;
          // justify-content: center;
          .card {
            cursor: pointer;
            height: 100%;
            width: 100%;
            flex-shrink: 0;
            // overflow: hidden;
            box-shadow: 8px 9px 16px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            backdrop-filter: blur(6px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            backdrop-filter: blur(6px);
            &[isloadingskeleton="true"] {
              background: #fff;
            }
            background: radial-gradient(rgb(202, 84, 253) 1%, rgba(17, 5, 20, 0.848));
      
            // &[isloadingskeleton="false"] {
            //   &::before {
            //     background: url("../../../assets/Images/left-clay.png");
            //     content: "";
            //     position: absolute;
            //     bottom: -50px;
            //     width: 193.06px;
            //     height: 193.06px;
            //     transform: rotate(-30deg);
            //     background-size: contain;
            //     z-index: -1;
            //     filter: blur(8px);
            //     left: -80px;
            //   }
      
            //   &::after {
            //     background: url("../../../assets/Images/left-clay.png");
            //     content: "";
            //     position: absolute;
            //     bottom: -50px;
            //     width: 193.06px;
            //     height: 193.06px;
            //     background-size: contain;
            //     z-index: -1;
            //     filter: blur(16px);
            //     right: -120px;
            //   }
            // }
      
            .image {
              position: relative;
              width: 100%;
      
              .imgText {
                opacity: 0.9;
                background: linear-gradient(
                  90deg,
                  #d7e677 25.66%,
                  rgba(226, 237, 156, 0.73) 61.78%,
                  rgba(255, 255, 255, 0) 95.76%
                );
                width: 100%;
                height: 28px;
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                padding: 8px;
      
                span {
                  color: #000;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
      
              .badge {
                position: absolute;
                top: -14px;
                left: -10px;
                @include flexRow(4px);
                align-items: center;
                background-color: $NEW-PRIMARY2-BASE;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 12px;
                padding: 4px 8px;
                > span {
                  color: $NEUTRAL-10;
                  font-size: 12px;
                  font-weight: 500;
                }
                .popoverBadge {
                  position: absolute;
                  top: 28px;
                  left: 0;
                }
              }
      
              display: flex;
              flex-direction: column;
      
              img {
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
                width: 100%;
                height: 250px;
                flex-shrink: 0;
                object-fit: cover;
              }
            }
      
            .description {
              padding: 0 16px 8px 16px;
              padding-top: 10px;
              display: flex;
              flex-direction: column;
              gap: 8px;
              align-items: flex-start;
              width: 100%;
              overflow: hidden;
              position: relative;
              &[isloadingskeleton="false"] {
                &::before {
                  background: url("../../../assets/Images/left-clay.png");
                  content: "";
                  position: absolute;
                  bottom: 0;
                  width: 193.06px;
                  height: 193.06px;
                  transform: rotate(-30deg);
                  background-size: contain;
                  z-index: -1;
                  filter: blur(12px);
                  left: -80px;
                }
      
                &::after {
                  background: url("../../../assets/Images/left-clay.png");
                  content: "";
                  position: absolute;
                  bottom: -20px;
                  width: 193.06px;
                  height: 193.06px;
                  background-size: contain;
                  z-index: -1;
                  filter: blur(16px);
                  right: -120px;
                }
              }
      
              .class {
                display: flex;
                gap: 8px;
      
                div:nth-child(1) {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: $SUCCESS-30;
                  border-radius: 4px;
                  padding: 4px 8px;
      
                  span {
                    color: $NEUTRAL-100;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                  }
                }
      
                div:nth-child(2) {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #d6d6d6;
                  border-radius: 4px;
                  padding-inline: 8px;
                  padding-block: 3.5px;
      
                  img {
                    width: 55px;
                    height: 17px;
                  }
                }
              }
      
              .title {
                p:nth-child(1) {
                  display: -webkit-box;
                  display: -moz-box;
                  display: -o-box;
                  display: -ms-box;
                  -webkit-box-orient: vertical;
                  box-orient: vertical;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  overflow: hidden;
                  color: $NEUTRAL-10;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                }
      
                p:nth-child(2) {
                  color: $NEUTRAL-20;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                }
              }
      
              .price {
                @include flexCol(0);
                .flexRow {
                  @include flexRow(4px);
                  align-items: center;
                  > div {
                    @include flexRow(0);
                    align-items: center;
                    padding: 2px 4px;
                    border-radius: 4px;
                    background-color: $NEUTRAL-10;
                    height: fit-content;
                    > span {
                      color: $NEW-PRIMARY2-BASE;
                      font-weight: 500;
                      font-size: 10px;
                    }
                  }
                }
                .lineThrough {
                  text-decoration: line-through;
                  color: $NEUTRAL-10;
                  font-size: 12px;
                }
                p {
                  color: $SUCCESS-30;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }

      .arrow {
        display: flex;
        gap: 1rem;
        width: 100%;
        justify-content: right;

        div {
          cursor: pointer;
          width: 44px;
          height: 44px;
          padding: 10px;
          border-radius: 50%;
          background: $NEUTRAL-10;
          box-shadow: 0px 8px 16px -6px rgba(24, 39, 75, 0.08),
            0px 6px 8px -6px rgba(24, 39, 75, 0.12);
        }

        div:nth-child(1) {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .wrapperClass {
      padding-inline: 12px !important;

      main {
        .mainContain {
          gap: 24px;
          display: flex;
          flex-direction: column;
          .detailClass {
            width: 100%;
            // margin-bottom: 24px;

            .typeClass {
              gap: 12px;
              display: flex;
              flex-direction: row;
              text-align: start;
              img {
                width: 80px;
                height: 80px;
              }

              h1 {
                font-size: 20px;
                line-height: 16px;
                text-align: start;
              }
              p {
                text-align: start;
              }
            }
          }

          .wrapperCard {
            gap: 0.8rem;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto;
            width: 100%;
            justify-content: flex-start;
            .card {
              width: 230px !important;

              .image {
                .imgText {
                  padding-left: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 660px) {
  .container {
    .wrapperClass {
      main {
        .mainContain {
          .wrapperCard {
            grid-template-columns: auto;
          }
        }
      }
    }
  }
}
.wrapperGradientText {
  display: flex;
  justify-content: center;
  .gradientText {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    background: $COLOR-GRADIENT-2;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
}
