@media (max-width: 768px) {
  .background {
    background-size: cover !important;

    .gradientBackground {
      .mainContent {
        width: 100%;
        height: auto;
        padding-inline: 1rem !important;

        .leftClay {
          width: 301px !important;
          height: 301px !important;
          bottom: 0 !important;
          left: -100px !important;
        }

        .rightClay {
          width: 350.466px !important;
          height: 350.466px !important;
          flex-shrink: 0;
          right: -150px !important;
          top: -90px !important;
        }

        .leftClayBottom {
          width: 447.495px !important;
          height: 447.495px !important;
          flex-shrink: 0;
          right: -200px !important;
          z-index: 1 !important;
          animation: up-and-down-right-bottom-r infinite 4s linear alternate !important;
          filter: blur(3px) !important;
        }
      }

      background-position: left !important;

      .imageGallery {
        width: auto !important;
        height: 200px !important;
        margin-top: 0 !important;
        position: absolute;
        top: 400px !important;

        img {
          padding-inline: 36px !important;
          padding-top: 0 !important;
        }

        width: 100% !important;
        height: auto !important;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        padding: 0 !important;
        margin-left: 20px !important;
        justify-content: center !important;
        margin-inline: 0 !important;
        text-align: center !important;
        margin-top: 360px !important;
        row-gap: 0 !important;

        .description {
          font-size: 14px !important;
          margin-top: 0 !important;
        }

        .description {
          h1 {
            font-size: 20px !important;
            color: white;
            line-height: 30px !important;
          }

          p {
            font-size: 14px !important;
            padding-inline: 1rem !important;
            text-align: center !important;
            line-height: 20px !important;
          }

          .buttonGallery {
            width: 200px !important;
            justify-content: center !important;
            margin-inline: auto !important;
            margin-top: 0 !important;
            padding: 0 !important;

            div {
              font-size: 14px !important;
            }
          }
        }
      }

      .headerPageTitle {
        .lineSeperator {
          display: none;
        }
      }

      .floatBanner {
        padding-top: 20px !important;

        .carouselBanner {
          .arrowLeft,
          .arrowRight {
            top: 25% !important;
            width: 24px !important;
            height: 24px !important;
          }

          .arrowLeft {
            left: 10px !important;
          }

          img {
            width: 90% !important;
            height: auto !important;
          }
        }
      }

      .phoneMockup {
        img {
          width: 205px !important;
          height: 225px !important;
        }
      }
    }
  }
}

@media (max-width: 1060px) {
  .background {
    &::after {
      right: 260px !important;
      top: 380px !important;
    }
  }
}

@media (max-width: 880px) {
  .background {
    &::after {
      right: 190px !important;
      top: 400px !important;
    }
  }
}

@media (max-width: 768px) {
  .background {
    &::after {
      right: 130px !important;
      top: 180px !important;
    }
  }
}

@media (max-width: 417px) {
  .background {
    &::after {
      right: 130px !important;
      top: 180px !important;
    }
  }
}

@media (max-width: 337px) {
  .background {
    &::after {
      right: 130px !important;
      top: 260px !important;
    }
  }
}

@keyframes drible {
  0%,
  100% {
    transform: translateY(3%);
  }

  50% {
    transform: translateY(10%);
  }
}

.background {
  position: relative;
  width: auto;
  height: auto;
  width: 100vw;
  z-index: 0;

  .viewScrollBehindTopBar {
    height: 78px; // as height of topbar
    position: absolute;
    z-index: -999;
    top: -78px;
  }

  .doodleWrapper {
    position: relative;
    z-index: -1;

    .bg {
      position: fixed;
      z-index: -3;
      scale: 1;
      top: 78px;
      opacity: 0.8;
      // height: 100%;
      min-width: 100vw;
    }
  }

  .noDoodle {
    position: relative;
    // z-index: 1;
    // position: absolute;
    // width: 100%;
  }

  > *:not(.bg) {
    z-index: 10;
    // display: none;
  }

  .gradientBackground {
    z-index: 2;

    .mainContent {
      max-width: 1440px;
      margin: auto;

      &::after {
        content: "";
        height: 800px;
        width: 613px;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
      }

      &::before {
        content: "";
        height: 600px;
        width: 613px;
        position: absolute;
        z-index: -1;
      }

      &::after {
        content: "";
        height: 800px;
        width: 613px;
        position: absolute;
        z-index: -1;
        top: 200px;
        right: 0;
      }

      .leftClay {
        position: absolute;
        filter: blur(3px);
        z-index: -1;
        top: 300px;
        width: 794px;
        height: 794px;
        left: -220px;
        animation: moveUpDown 4s infinite linear;
        transform: rotate(-40deg);
      }

      .rightClay {
        position: absolute;
        filter: blur(3px);
        z-index: -1;
        top: -120px;
        width: 761.882px;
        height: 761.882px;
        flex-shrink: 0;
        right: -220px;
        transform: rotate(40deg);
        animation: rotates 4s infinite linear;
      }

      .leftClayBottom {
        position: absolute;
        z-index: -1;
        transform: rotate(-20deg);
        width: 1040.685px;
        height: 1040.685px;
        flex-shrink: 0;
        right: -400px;
        z-index: 1;
        animation: up-and-down-right-bottom infinite 4s linear alternate;
      }
    }

    width: 100%;
    height: 100%;
    background: url("../../../assets/Images/Group.png") no-repeat;
    background-size: cover;
    position: relative;

    .headerPageTitle {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      max-width: 513px;
      width: auto;
      color: white;
      margin-left: 45px;
      padding-top: 84px;

      h1 {
        font-size: 46px;
        line-height: 57px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }

      .lineSeperator {
        width: 90%;
        height: 1px;
        background-color: #d5c0dd;
        margin-block: 20px;
      }

      .voucher {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        max-width: 386px;
        row-gap: 12px;

        h1 {
          color: var(--Neutral-10, #fff);
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          color: var(--Neutral-10, #fff);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          /* 22.4px */
        }

        .enterVoucherPrakerja {
          display: flex;
          gap: 15px;

          input {
            padding: 10px 11px;
            background-color: transparent;
            width: 272px;
            border-radius: 8px;
            border: 1px solid var(--Neutral-10, #fff);
            background: linear-gradient(
              90deg,
              rgba(127, 109, 199, 0.5) 0%,
              rgba(130, 128, 202, 0.5) 100%
            );
            box-shadow: 0px 6px 24px 4px rgba(162, 166, 180, 0.25);
            backdrop-filter: blur(5.199999809265137px);
            color: white;
            font-size: 1rem;

            &::placeholder {
              font-size: 1rem;
              line-height: 20px;
              color: white;
            }

            &:focus {
              border-color: white;
              outline: none;
            }
          }

          .buttonVoucherPrakerja {
            display: flex;
            width: 99px;
            height: 44px;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            flex-shrink: 0;
            border-radius: 8px;
            background: #fff;
            color: #9360a8;
            font-weight: 600;
            font-size: 1rem;
            box-shadow: 0 6px 2px #2b1c31;
            transition: 0.3s;

            &:active {
              transform: translateY(2px);
              box-shadow: none;
            }
          }
        }
      }

      .counter {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding-top: 20px;

        .year,
        .course,
        .studies {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          h1 {
            font-size: 40px;
            font-weight: 500;
            line-height: 44px;
          }

          p {
            font-stretch: 14px;
            line-height: 1rem;
            font-weight: normal;
          }
        }

        span {
          width: 1px;
          height: 60px;
          background-color: #d5c0dd;
          margin-inline: 49px;
        }
      }
    }
  }

  .titleSearchClass {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    // padding-block: 60px;
    text-align: center;

    h1 {
      color: #232323;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;

      span {
        background: linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
      }
    }
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes rotates {
  0%,
  100% {
    // top: -6%;
    transform: rotate(60deg);
  }

  50% {
    // top: -7%;
    transform: rotate(68deg);
  }
}

@keyframes up-and-down-right-bottom {
  0%,
  100% {
    top: 22%;
  }

  50% {
    top: 24%;
  }
}

@keyframes up-and-down-right-bottom-r {
  0%,
  100% {
    top: 14%;
  }

  50% {
    top: 14.5%;
  }
}

@media (max-width: 1100px) {
  .background {
    .titleSearchClass {
      text-align: center;
      padding-bottom: 24px !important;
      padding-top: 42px !important;

      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;

        span {
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
        }
      }
    }
  }
}
/* Sembunyikan scrollbar secara default */
.background::-webkit-scrollbar {
  display: none !important;
}

/* Tampilkan scrollbar hanya ketika ada aktivitas scroll */
// body:hover {
//   overflow-y: auto;
// }
