@import "../../../../../theme/variables.scss";

.container {
  padding: 16px 24px;
  overflow: visible;
  .hyperlink {
    cursor: pointer;
    color: #3d54cd !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    > :last-child {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .input {
      display: flex;
      gap: 24px;
      align-items: center;
      > :first-child {
        width: 280px;
        color: $NEUTRAL-90;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
      > :last-child {
        flex: 1;
      }
      .imageInputWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
        border-radius: 8px;
        border: 1px dashed #9360a8;
        background: var(--New-Primary-2-Primary-10, #f4eff6);
        height: 330px;
        > :nth-child(2) {
          color: var(--New-Primary-2-Primary-Base, #9360a8);
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        > :nth-child(3) {
          color: $NEUTRAL-60;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          white-space: pre-line;
        }
        > button {
          background: linear-gradient(#f4eff6, #f4eff6) padding-box,
            linear-gradient(137deg, #9360a8 35.02%, #46b7e9 125.87%) border-box;
        }
      }
      .imagePreviewWrapper {
        position: relative;
        transition: 0.2s;
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
          transition: 0.2s;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          opacity: 0;
        }
        &:hover {
          .overlay {
            opacity: 1;
          }
        }
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
