@import "../../../theme/variables.scss";

.container {
  padding: 6px 12px;
  border: 1px solid $NEUTRAL-40;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  background-color: $NEUTRAL-10;

  > span {
    flex-grow: 1;
    font-family: $BASE-FONT-FAMILY;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .optionsWrapper {
    position: absolute;
    min-width: 100%;
    border-radius: 8px;
    z-index: 10;
    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12), 
                0px 8px 18px -6px rgba(24, 39, 75, 0.12);
    background-color: $NEUTRAL-10;
    padding: 4px 0;
    overflow: hidden;

    > div {
      padding: 6px 12px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL-30;
      }

      &:hover {
        background-color: $NEUTRAL-20;
        font-weight: 500;
      }
    }
  }
}
