@import "../../../../theme/variables.scss";

.container {
  display: grid;
  grid-template-columns: 140px 1fr;
  border: 1px solid $NEUTRAL-30;
  border-radius: 16px;
  overflow: hidden;
  height: 140px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    transform: translateY(-5px);
  }
  .tag {
    border-radius: 17px;
    display: flex;
    width: 90px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--Neutral-20, #f5f5f5);
    > p {
      color: var(--Neutral-90, #404040);
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      text-transform: capitalize;
    }
    &[status="PUBLISH"] {
      background: var(--Success-Success-20, #ddf9a4);
    }
    &[status="DRAFT"] {
      background: var(--New-Primary-3-20, #dbf1fb);
    }
    &[status="UNPUBLISH"] {
      background: var(--Danger-Danger-20, #fac8a3);
    }
  }
  .cardImage {
    width: 140px;
    height: 140px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cardContentWrapper {
    padding: 16px;
    width: 100%;
    .headSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > :first-child {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      .tagWrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        .view {
          display: flex;
          gap: 4px;
          > p {
            color: $NEUTRAL-90;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
  .dateWrapper {
    padding-top: 8px;
    padding-bottom: 10px;
    display: flex;
    gap: 8px;
    .date {
      color: $NEUTRAL-60;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .textWrapper {
    height: 100%;
    > p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #000;
      line-clamp: 2;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
